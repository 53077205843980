<template>
	<div class="a-w-100 a-h-100">
		<span class="a-fs-16 a-fw-700">申请信息</span>
		<el-form :model="applyform" :rules="rules" ref="applyForm" label-width="112px" class="applyform"
			label-position="left">
			<el-form-item label="项目名称" prop="proName">
				<el-input v-model="station.incomeName" maxlength="50" readonly></el-input>
			</el-form-item>
			<el-form-item label="站点名称" prop="name">
				<el-input v-model="station.name" maxlength="50" readonly></el-input>
			</el-form-item>
			<el-form-item label="申请数量(台)" prop="applyDeviceNum">
				<el-input v-model="applyform.applyDeviceNum" type="number"
					@input="applyform.applyDeviceNum = applyform.applyDeviceNum.replace(/^\.+|[^\d]+/g, '')"
					maxlength="5"></el-input>
			</el-form-item>
			<le-input-address-point label="详细地址" :province.sync="applyform.addressProvinceCode"
				:city.sync="applyform.addressCityCode" :area.sync="applyform.addressDistrictCode"
				:address.sync="applyform.detailAddress" :lat.sync="applyform.lat" :lng.sync="applyform.lng"
				:notnull="true"></le-input-address-point>
			<el-form-item label="收货人" prop="personName">
				<el-input v-model="applyform.personName" maxlength="5"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" prop="mobile">
				<el-input v-model="applyform.mobile" maxlength="5" type="number"></el-input>
			</el-form-item>
			<div class="a-line-t-e0">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="onSubmit">立即提交</el-button>
				<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancle">
					&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
				</el-button>
			</div>
		</el-form>
	</div>

</template>

<script>
	import util from '../../utils/validate';
	export default {
		props: {
			stationId: {
				type: Number,
			}
		},
		data() {
			var validateMobile = (rule, value, callback) => {

				if (!util.isvalidPhone(value)) {
					callback(new Error('请输入正确的手机号!'));
				}
				callback();

			};
			return {
				station: {
					name: '',
					incomeName: ''
				},

				applyform: {
					applyDeviceNum: null,
					lng: '',
					lat: '',
					detailAddress: "",
					addressProvinceCode: "",
					addressCityCode: "",
					addressDistrictCode: "",
					personName: "",
					mobile: null
				},
				rules: {
					applyDeviceNum: [{
						required: true,
						message: '请输入申请数量',
						trigger: 'blur'
					}],
					personName: [{
						required: true,
						message: '请输入收货人',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'blur'
					}, {
						validator: validateMobile,
						trigger: 'blur'
					}]
				},
			}
		},
		watch: {
			stationId: {
				handler(val) {
					if (val) {
						this.getStationDetail();
					}
				},
				immediate: true
			}
		},
		mounted() {

		},
		methods: {
			/**
			 * 获取站点详细信息
			 */
			getStationDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getStationDetail,
					params: {
						stationId: this.stationId
					}
				}).then(({
					data
				}) => {
					this.station.name = data.station.name
					this.station.incomeName = data.incomeDistri.name
				})
			},
			onSubmit() {
				let that = this
				this.$refs.applyForm.validate((valid) => {
					if (valid) {
						that.$Axios._post({
							url: that.$Config.apiUrl.applyStationDevice,
							params: {
								...that.applyform,
								stationId: that.stationId
							}
						}).then(({
							data
						}) => {
							/**
							 * 发送刷新
							 */
							this.$eventBus.$emit('station_fresh_deviceDelivery')
							this.$message.success("申请设备成功")
							this.$emit('success')
						})
					}
				});

			},
			handlerCancle() {
				this.$emit('cancel')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.applyform {
		position: relative;
		height: 100%;

		/deep/ .el-form-item {
			margin-top: 16px;
			margin-bottom: 24px;
		}

		/deep/ .el-input__inner {
			width: 376px;

		}

		/deep/ .s-search-label {
			width: 112px;
			justify-content: flex-start;

			&+div {
				margin-left: 0;
			}

			span {
				&:nth-of-type(2) {
					margin-top: 0;
					margin-left: 4px;
				}
			}
		}

		.footerBox {
			left: -24px;
			right: 24px;
			position: absolute;
			bottom: -30px;
			background: #fff;
			padding-bottom: 24px;
			margin-bottom: 0;
		}
	}

	.el-main {
		height: calc(100vh - 195px);
	}
</style>
