<template>
    <div class="progress a-w-100 a-br-4 a-bg-white a-ptb-20 a-plr-25">
        <div class="">
            <span class="a-fs-16 a-fw-700">建设进度</span>
            <div class="a-mt-40 stepbox">
                <el-steps :active="stepActive" align-center>
                    <el-step v-for="(item, index) in stepStatus" :key="index" :title="item.stepText"
                        :description="item.stepTime">
                        <template #icon v-if="item.status == 0 || item.status == 1">
                            <img src="../../assets/icon/wait-icon.png" alt="" style="height:16px;width:16px;">
                        </template>
                    </el-step>

                </el-steps>
            </div>
        </div>
        <div class="a-mt-45">
            <span class="a-fs-16 a-fw-700">出库详情</span>
            <div class="outboundBox a-mt-16">
                <div class="outboundBox-item">
                    <span class="a-fw-700">申请信息</span>
                    <div class="a-mt-16 a-line-t-e0">
                        <div v-if="deviceApply.mobile" class="outboundBox-item-box a-flex-rfsc a-flex-wrap">
                            <div>
                                <span>申请数量</span>
                                <span>{{  deviceApply.applyDeviceNum  }}</span>
                            </div>
                            <div>
                                <span>收货人</span>
                                <span>{{  deviceApply.personName  }}</span>
                            </div>
                            <div>
                                <span>联系电话</span>
                                <span>{{  deviceApply.mobile  }}</span>
                            </div>
                            <div>
                                <span>收货地址</span>
                                <span>{{  deviceApply.addressProvince + deviceApply.addressCity +
                                deviceApply.addressDistrict +
                                deviceApply.detailAddress}}</span>
                            </div>
                            <div>
                                <span>申请人</span>
                                <span>{{  deviceApply.applyUserName  }}</span>
                            </div>
                            <div>
                                <span>申请人电话</span>
                                <span>{{  deviceApply.applyUserMobile  }}</span>
                            </div>
                        </div>
                        <div v-else class="a-flex-rcc a-c-second a-fs-14 a-mt-24">
                            <span>暂无申请信息，</span>
                            <span class="a-c-blue a-cursor-p">去申请设备</span>
                            <span class="a-fs-12 a-ml-08">&gt;</span>
                        </div>
                    </div>
                </div>

                <div class="outboundBox-item">
                    <span class="a-fw-700">出库信息</span>
                    <div class="a-mt-16 a-line-t-e0">
                        <div v-if="deviceApply.status > 0" class="outboundBox-item-box a-flex-rfsc a-flex-wrap wlxx">
                            <div>
                                <span>使用教程及规范</span>
                                <span>无</span>
                            </div>
                            <div>
                                <span>宣传海报</span>
                                <span>无</span>
                            </div>
                            <div>
                                <span>联系电话</span>
                                <span>{{  deviceApply.deliveryOpUserMobile  }}</span>
                            </div>
                            <div>
                                <span>发货人</span>
                                <span>{{  deviceApply.deliveryOpUserName  }}</span>
                            </div>
                            <div>
                                <span>发货数量</span>
                                <span>{{  deviceApply.deliveryDeviceNum  }}</span>
                            </div>
                            <div>
                                <span>发货地址</span>
                                <span>{{  deviceApply.deliveryAddress  }}</span>
                            </div>
                            <div>
                                <span>物流公司</span>
                                <span>{{  deviceApply.shipCompany  }}</span>
                            </div>
                            <div>
                                <span>物流单号</span>
                                <span>{{  deviceApply.shipNumber  }}</span>
                            </div>
                        </div>
                        <div v-else class="a-flex-rcc a-c-second a-fs-14 a-mt-24">
                            <span>暂无出库信息</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="a-mt-56">
            <span class="a-fs-16 a-fw-700">施工详情</span>
            <div v-if="station.detailedState >= 12" class="outboundBox-item-box a-flex-rfsc a-flex-wrap sgxq">
                <div v-if="buildInfo.stationImage && buildInfo.stationImage.length > 0">
                    <div>站点全景</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.stationImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.stationImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.switchImage && buildInfo.switchImage.length > 0">
                    <div>空开照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.switchImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.switchImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.electricMeterImage && buildInfo.electricMeterImage.length > 0">
                    <div>电表照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.electricMeterImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.electricMeterImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.deviceImage && buildInfo.deviceImage.length > 0">
                    <div>设备照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.deviceImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.deviceImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.lineImage && buildInfo.lineImage.length > 0">
                    <div>走线照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.lineImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.lineImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.earthLineImage && buildInfo.earthLineImage.length > 0">
                    <div>地线照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.earthLineImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.earthLineImage.length  }}</span>
                    </div>
                </div>
                <div v-if="buildInfo.leafletImage && buildInfo.leafletImage.length > 0">
                    <div>宣传单照片</div>
                    <div>
                        <el-image class="sgxqimg" :src="require('../../assets/icon/img-icon.png')"
                            :preview-src-list="buildInfo.leafletImage">
                        </el-image>
                        <span class="a-c-blue a-ml-03 a-fs-14">{{  buildInfo.leafletImage.length  }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="a-mt-16 a-c-second a-fs-14">
                暂无施工详情
            </div>
        </div>
        <div class="a-mt-56">
            <span class="a-fs-16 a-fw-700">验收结果</span>

            <div v-if="station.detailedState >= 14" class="outboundBox-item-box a-flex-rfsc a-flex-wrap sgxq">

                <div>
                    <div>验收结果</div>
                    <div v-if="station.detailedState == 15" style="color:#FF3B30">
                        拒绝
                    </div>
                    <template v-else>
                        <div v-if="station.detailedState == 14 || station.detailedState > 15">
                            通过
                        </div>
                        <div v-else>
                            无
                        </div>
                    </template>

                </div>
                <div>
                    <div>验收内容</div>
                    <div v-if="station.detailedState == 15" style="color:#FF3B30">
                        {{  station.detailedStateMessage  }}
                    </div>
                    <template v-else>
                        <div v-if="station.detailedState == 14 || station.detailedState > 15">
                            {{  station.detailedStateMessage  }}
                        </div>
                        <div v-else>
                            无
                        </div>
                    </template>
                </div>
            </div>

            <div v-else class="a-mt-16 a-c-second a-fs-14">
                暂无验收详情
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        stationId: {
            type: Number,
        }
    },
    data() {
        return {
            stepStatus: [],
            stepActive: 1,
            station: {

            },//设备基本信息
            deviceApply: {

            },//设备申请信息
            buildInfo: {

            },//设备施工信息
        }
    },
    watch: {
        stationId: {
            handler(val) {
                if (val) {
                    this.getStationBuildStatus();
                    this.getStationDeviceShipInfo();
                    this.getStationDetail();
                    this.getStationBuildInfo();
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.$eventBus.$on('station_fresh_deviceDelivery', () => {
            this.getStationBuildStatus();
            this.getStationDeviceShipInfo();
            this.getStationDetail();

        })
        this.$eventBus.$on('station_fresh_buildInfo', () => {
            this.getStationBuildStatus();
            this.getStationDetail();
            this.getStationBuildInfo();
        })
        this.$eventBus.$on('station_fresh_checkInfo', () => {
            this.getStationBuildStatus();
            this.getStationDetail();
        })

    },
    methods: {
        getStationBuildStatus() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getStationBuildStatus,
                params: {
                    stationId: this.stationId
                }
            }).then(({ data }) => {
                console.log('getStationBuildStatus', data)
                this.stepStatus = data
                this.stepStatus.map((item, index) => {
                    if (item.stepStatus >= 2) {
                        this.stepActive = index + 1
                    }
                })
            })
        },
        /**
         * 获取站点详细信息
         */
        getStationDetail() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getStationDetail,
                params: {
                    stationId: this.stationId
                }
            }).then(({ data }) => {
                console.log('getStationDetail', data)
                this.station = data.station
            })
        },
        /**
         * 获取站点出库详情
         */
        getStationDeviceShipInfo() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getStationDeviceShipInfo,
                params: {
                    stationId: this.stationId
                }
            }).then(({ data }) => {
                this.deviceApply = data
            })
        },
        /**
         * 获取站点施工信息
         */
        getStationBuildInfo() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getStationBuildInfo,
                params: {
                    stationId: this.stationId
                }
            }).then(({ data }) => {
                if (!data) return
                this.buildInfo = {
                    ...data,
                    stationImage: data.stationImage ? JSON.parse(data.stationImage) : [],
                    switchImage: data.switchImage ? JSON.parse(data.switchImage) : [],
                    electricMeterImage: data.electricMeterImage ? JSON.parse(data.electricMeterImage) : [],
                    deviceImage: data.deviceImage ? JSON.parse(data.deviceImage) : [],
                    earthLineImage: data.earthLineImage ? JSON.parse(data.earthLineImage) : [],
                    lineImage: data.lineImage ? JSON.parse(data.lineImage) : [],
                    leafletImage: data.leafletImage ? JSON.parse(data.leafletImage) : [],
                }
            })
        },

    }
}
</script>
<style lang="scss" scoped>
.progress {
    margin-bottom: 24px;
}

.stepbox {
    width: 100%;

    /deep/ .el-steps {
        .el-step {
            .el-step__icon {
                border-color: white;
                width: 40px;

                .el-step__icon-inner {
                    height: 8px;
                    width: 8px;
                    border-radius: 100%;
                    font-size: 0px;
                    line-height: 14px;
                }
            }

            .el-step__title {
                font-size: 14px;
                color: #909499;
            }

            .el-step__description {
                color: #909499;
                font-size: 14px;
            }

            .is-finish {
                &.el-step__title {
                    color: #303133;
                }

                .el-step__icon-inner {
                    color: #007AFF;
                    background-color: #007AFF;
                }
            }

            .is-process,
            .is-wait {
                .el-step__icon-inner {
                    color: #ADB0B3;
                    background-color: #ADB0B3;
                }
            }
        }
    }
}

.outboundBox {
    border: 1px solid #EBF0F5;
    padding: 0 24px;

    &-item {
        padding: 16px 0 24px;

        &-box {
            >div {
                width: 33%;
                display: flex;
                flex-flow: row;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 16px;

                >* {
                    color: #606366;
                    font-size: 14px;
                    line-height: 22px;

                    &:nth-of-type(2) {
                        color: #303133;
                        margin-left: 16px;
                        max-width: 200px;
                    }
                }
            }

            &.wlxx {
                >div {

                    &:nth-of-type(3n+1) {
                        >* {
                            &:nth-of-type(1) {
                                min-width: 98px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sgxqimg {
    width: 14px;
    height: 12px;
}

.sgxq {
    >div {

        &:nth-of-type(3n+1) {
            >* {
                &:nth-of-type(1) {
                    min-width: 70px;
                }
            }
        }
    }
}
</style>