<template>
	<div class="a-h-100">
        <div class="a-w-100 a-h-100 a-bg-white a-plr-25" style="position:relative">
            <EarningsTrend class="stationStat a-w-100" :id="id" :params="params" :infoType="infoType"></EarningsTrend>
        </div>
	</div>
</template>
<script>
	import util from '../../../src/utils/util'
    import EarningsTrend from '../businessAnalysis/info-child/earningsTrend.vue';
	export default {
		components: {
			EarningsTrend
		},
		props: {
			stationId: {
				required: true,
			}
		},
		data() {
			return {
				util:util,
				infoType: 'station',
                params: '',
                id: ''
			}
		},

		mounted() {},

		watch: {
			stationId: {
				handler(val) {
					if (val) {
                        this.id = val
						this.params = {
                            stationId: val
                        }
					}
				},
				immediate: true
			}
		},
		methods: {

		}
	}
</script>
<style lang="scss" scoped>
    .stationStat{
        margin-top: 0 !important;
    }
    .a-h-100{
        height: 100%;
    }
</style>
