<template>
	<div class="a-w-100 a-br-4 a-bg-white a-ptb-20 a-plr-25">
		<div class="a-w-100 a-flex-rsbc">
			<span class="a-c-333 a-fs-16 a-fw-b">{{total}}台设备</span>
			<div class="a-flex-rcc">
				<el-dropdown placement="bottom-start" @command="handlerOperationDev">
				    <el-button class="s-btn-batch">批量操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
				    <el-dropdown-menu slot="dropdown">
				        <el-dropdown-item v-for="item in optionsBatch" :command='item.value+"#"' :key="item.value" :value="item.value">{{ item.label }}</el-dropdown-item>
				    </el-dropdown-menu>
				</el-dropdown>
				<el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="bindDevice">设备</el-button>
			</div>
		</div>
		<le-pagview ref="deviceListPage" :total.sync="total" :pageParam="pageParam" @setData="setTableData" v-if="pageParam">
			<el-table 
				ref="deviceList" 
				:data="tableData" 
				:row-key="getRowKey"
				@select="selectItem"
				@select-all="selectAll"
				:highlight-current-row="true" 
				@filter-change='filterFun'
				style="width: 100%">
				<el-table-column type="selection" fixed="left" align="center" width="55" reserve-selection  class="device-checkBox"></el-table-column>
				<el-table-column prop="deviceCode" label="设备号" min-width="150">
					<template slot-scope="{ row }">
						<span class="a-c-blue font-point" @click="getDeviceInfo(row)">{{ row.deviceCode?row.deviceCode:'-' }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="stationName" label="所属站点" min-width="240">
					<template slot-scope="{ row }">
						<span>{{ row.stationName?row.stationName:'-' }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="companyName" label="所属加盟商" min-width="200">
					<template slot-scope="{ row }">
						<span>{{ row.companyName?row.companyName:'-' }}</span>
					</template>
				</el-table-column>
				<el-table-column 
					prop="onlineStatus" 
					:label="pageParam.params.status | initTablelabel(optionsDeviceStatus,'设备状态')" 
					column-key="onlineStatus"
					:filter-multiple='false'
					:filters="optionsDeviceStatus"
					min-width="100">
					<template slot-scope="{ row }">
						<div class="a-flex-rfsc">
							<div class="online-dev" v-if="row.onlineStatus==1"></div>
							<div class="offline-dev" v-if="row.onlineStatus==0"></div>
							<div class="damage-dev" v-if="row.onlineStatus==2"></div>
							<span class="a-ml-5">{{ row.onlineStatus | initDic(optionsDeviceStatus) }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column 
					prop="name" 
					label="插座信号" 
					column-key="csq"
					:filter-multiple='false'
					:filters="csqDic"
					min-width="120">
					<template slot-scope="{ row }">
						<div>
							<span class="a-fs-14 c29CC29" v-if="row.csq && row.csq >= 18">强(-1db)</span>
							<span class="a-fs-14 cFFA900" v-else-if="row.csq && row.csq >= 11 && row.csq < 18">中(-50db)</span>
							<span class="a-fs-14 cFF3B30" v-else-if="row.csq && row.csq < 11 ">弱(-98db)</span>
							<span class="a-fs-14 a-c-normal" v-else>无信号</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="启用/禁用" min-width="104">
					<template slot-scope="scope">
						<el-switch
							@change="devUseOrNotUse(scope.row,scope.$index)"
							v-model="scope.row.canUse"
							:active-value="1"
							:inactive-value="0"
							active-color="#007AFF"
							inactive-color="#F56C6C">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column 
					prop="name" 
					label="是否RFID" 
					column-key="canRfid"
					:filter-multiple='false'
					:filters="yesOrNoDic"
					min-width="120">
					<template slot-scope="{ row }">
						<span>{{ row.canRfid | initDic(yesOrNoDic) }}</span>
					</template>
				</el-table-column>
				<el-table-column 
					prop="name" 
					label="是否人脸" 
					column-key="canFace"
					:filter-multiple='false'
					:filters="yesOrNoDic"
					min-width="120">
					<template slot-scope="{ row }">
						<span>{{ row.canFace | initDic(yesOrNoDic) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="deviceCategroyText" label="设备种类" min-width="120"></el-table-column>
				<el-table-column prop="deviceTypeText" label="设备类型" min-width="120"></el-table-column>
				<el-table-column prop="createTimeText" label="创建时间" min-width="200"></el-table-column>
				<el-table-column prop="installTimeText" label="安装时间" min-width="200"></el-table-column>
				<el-table-column prop="name" label="操作" min-width="112" fixed="right" >
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="设备详情" placement="top"> 
							<img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getDeviceInfo(scope.row)" />
						</el-tooltip>
						<el-tooltip class="item a-ml-12" effect="dark" content="二维码" placement="top">
							<img @click="setQrcode(scope.row)" src="../../assets/icon/qrcode.png" class="a-wh-16" />
						</el-tooltip>
						<el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
							<el-dropdown placement="bottom-start" @command="handlerOperationDev">
								<img src="../../assets/icon/more-operation.png" class="a-wh-16" />
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item 
										:disabled="(scope.row.onlineStatus==0 && (item.value==2 || item.value==5)) 
													|| (!scope.row.stationId && item.value==1)"
										v-for="item in optionsMoreOperation" 
										:command='item.value+"#"+scope.$index' 
										:key="item.value" 
										v-show="(scope.row.deviceCategroy == 2 && (item.value == 1 || item.value == 6 || item.value == 7)) || (scope.row.deviceCategroy != 2 && item.value != 7)"
										:value="item.value">{{ item.label }}</el-dropdown-item>
								</el-dropdown-menu>
								
							</el-dropdown>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
		</le-pagview>
		
		<dev-qrcode ref="devQrCode" />
		<dev-param-set ref="paramSet" @paramsSet="paramsSetResult"></dev-param-set>
		<dev-param-result ref="paramResult" @resetParams="resetParams"></dev-param-result>
		<dev-reboot-result ref="rebootResult" :tableData="rebootResult"></dev-reboot-result>
		<a-poppup ref="aPoppup">
		    <div class="pop-content a-flex-rcc">
		        <!-- <el-progress class="progress" :stroke-width="8" :percentage="percentage" color="#007AFF" :show-text="false"></el-progress> -->
		        <div class="progress">
		            <div class="progress-item"></div>
		        </div>
		    </div>
		</a-poppup>
	</div>
</template>
<script>
	import util from '../../../src/utils/util'
	import DevQrcode from '../device/child/dev-qrcode.vue';
	import DevParamSet from '../device/child/dev-paramSet.vue';
	import DevParamResult from '../device/child/dev-param-result.vue';
	import APoppup from '../components/poppup/a-poppup.vue'
	import DevRebootResult from '../device/child/dev-reboot-result.vue';
	export default {
		components:{
		    DevQrcode,
		    DevParamSet,
		    DevParamResult,
		    APoppup,
		    DevRebootResult
		},
		props: {
			stationId: {
				required: true
			}
		},
		data() {
			return {
				util:util,
				total:0,
				pageParam: '',
				
				tableData: [],
				selectData: [],//选中的列表
				optionsDeviceType: {
				    url: this.$Config.apiUrl.getDeviceType,
				    method: "post",
				    params: {},
				    labelKey: "name",
				    valueKey: "id",
				    searchKey: "key",
				},
				optionsDeviceStatus: [{
				    text: "离线",
				    value: 0,
				}, {
				    text: "在线",
				    value: 1,
				}, {
				    text: "故障",
				    value: 2,
				}],
				optionsDeviceUseStatus: [{
				    label: "启用",
				    value: 1,
				}, {
				    label: "禁用",
				    value: 0,
				}],
				optionsBatch: [{
				    label: "解绑",
				    value: 1,
				}, {
				    label: "远程重启",
				    value: 2,
				}, {
				    label: "启用",
				    value: 3,
				}, {
				    label: "禁用",
				    value: 4,
				}, {
				    label: "参数下发",
				    value: 5,
				}],
				optionsMoreOperation: [{
					label: "解绑",
					value: 1,
				}, {
					label: "远程重启",
					value: 2,
				}, {
					label: "参数下发",
					value: 5,
				}, {
					label: "编辑",
					value: 6,
				}, {
					label: "远程开门",
					value: 7,
				}],
				optionsDev: [{
				    label: "XLS批量导入",
				    value: 1,
				}, {
				    label: "新增设备",
				    value: 2,
				}],
				qrVal: '',
				rebootResult: [],//重启结果
				isSelectAll: false,//是否全选中
				csqDic: [],// 信号字典
				yesOrNoDic: [],
			}
		},
		
		filters:{
		    initDic (value,dic) {
		        if(value === '' || value === undefined || value === null){
		            return ''
		        }else{
		            for(var i=0; i<dic.length;i++){
		                if(value==dic[i].value){
		                    return dic[i].text
		                }
		            }
		        }
		    },
		},

		mounted() {
			this.$getDic('csqType').then(res=>{ this.csqDic = res; })
			this.$getDic('yesOrNo').then(res=>{ this.yesOrNoDic = res; })
		},

		watch: {
			stationId: {
				handler(val) {
					if (val) {
						this.pageParam= {
							url: this.$Config.apiUrl.getDeviceList,
							method: "post",
							params: {
								stationId: this.stationId,
								deviceCode: '',// 设备码 
								stationName: '',
								companyId: "",// 加盟商
								installStartTime: "",
								installEndTime: "",
								deviceType: "",
								deviceCategroy: '',// 种类筛选
								csqCode: '',// 信号筛选
								status: "", //设备状态
								canUse: '',//启用状态
								canRfid: '',
								canFace: '',
							},
							freshCtrl: 1,
						}
					}
				},
				immediate: true
			}
		},
		methods: {
			//获取设备列表
			setTableData(data,pagenum) {
			    this.tableData = data;
			},
			getRowKey (row) {
			    return row.deviceCode
			},
			// 单选
			selectItem(list, row) {
			    this.selectData = list
			    this.selectData = Array.from(new Set(this.selectData))
			    console.log(this.selectData)
			},
			// 全选
			selectAll(selection) {
			    // 若取消全选，当前页的选中数据移除
			    // this.isSelectAll = !this.isSelectAll
			    // if (!this.isSelectAll) {
			    //     const deviceCodes = this.tableData.map((i) => {
			    //         return i.deviceCode
			    //     })
			    //     for (var i = 0; i < this.selectData.length; i++) {
			    //         if (deviceCodes.indexOf(this.selectData[i].deviceCode) > -1) {
			    //             this.selectData.splice(i, 1)
			    //             i--
			    //         }
			    //     }
			    // }
			    // const arr = [...selection, ...this.selectData]
			    this.selectData = selection
			    this.selectData = Array.from(new Set(this.selectData))
			},
			// 站点绑定设备
			bindDevice () {
				this.$prompt('', '绑定设备', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator: (val)=>{
						if(!val){
							return '请输入设备号'
						}
					}
				}).then(({ value }) => {
					this.$Axios._post({
						url: this.$Config.apiUrl.bindDevice,
						method: "post",
						params: {
							deviceCode: value,
							stationId: this.stationId
						}
					}).then(res => {
						if(res.result.code == 0){
							this.$message.success('操作成功')
							this.pageParam.freshCtrl++
						}else{
							this.$message.error(res.result.message)
						}
					}).catch(()=>{ })
				}).catch(() => {});
			},
			//条件筛选
			filterFun(value){
				for(var key in value){
					if(key=='onlineStatus'){
						if(value[key].length==0){
							this.pageParam.params.status = ''
						}else{
							this.pageParam.params.status = value[key][0]
						}
					}
					if(key=='csq'){
						if(value[key].length==0){
							this.pageParam.params.csqCode = ''
						}else{
							this.pageParam.params.csqCode = value[key][0]
						}
					}
					if(key=='canRfid'){
						if(value[key].length==0){
							this.pageParam.params.canRfid = ''
						}else{
							this.pageParam.params.canRfid = value[key][0]
						}
					}
					if(key=='canFace'){
						if(value[key].length==0){
							this.pageParam.params.canFace = ''
						}else{
							this.pageParam.params.canFace = value[key][0]
						}
					}
				}
				this.$refs['deviceListPage'].pageNum = 1;
				this.pageParam.freshCtrl++
			},



			// 批量操作表格
			handlerOperationDev (commands) {
				let command = commands.split('#')[0]
				let index = commands.split('#')[1]
				let arr = this.selectData.length ? this.selectData : []
				let devparamType = 'set'
				if(index) {
					// 如果有index值,则是表格单独操作, 否则为批量操作
					arr = [this.tableData[index]]
					devparamType = 'single'
				}
				if(!arr.length) {
					this.$message.warning('请至少选择一台设备')
					return
				}
				switch (command) {
					case '1':
						// 解绑
						this.$confirm('是否确认将所选设备解除绑定?', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(_=>{
							let reqData = []
							arr.map(item=>{
								reqData.push(item.deviceCode)
							})
							this.unbindDev(reqData)
						}).catch(_=>{})
						break;
					case '2':
						// 重启
						this.$confirm('是否确认远程重启所选设备?', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(_=>{
							let reqData = []
							arr.map(item=>{
								reqData.push(item.deviceCode)
							})
							this.batchRebootDev(reqData)
						}).catch(_=>{
						})
						break;
					case '3':
						// 启用
						this.$confirm('是否确认启用所选设备?', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(_=>{
							let reqData = []
							arr.map(item=>{
								reqData.push(item.deviceCode)
							})
							this.setCanUse(reqData)
						}).catch(_=>{
							this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0
						})
						break;
					case '4':
						// 禁用
						this.$confirm('是否确认禁用所选设备?', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(_=>{
							let reqData = []
							arr.map(item=>{
								reqData.push(item.deviceCode)
							})
							this.setNotUse(reqData)
						}).catch(_=>{
							this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0
						})
						break;
					case '5':
						// 参数下发
						this.devParamSet(arr,devparamType)
						break;
					case '6':
						// 设备编辑
						this.$router.push({
							path:'/device/device-edit',
							query: {
								deviceCode: arr[0].deviceCode
							}
						})
						break;
					case '7':
						// 门禁设备开门
						this.$confirm('是否确认远程开门?', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(_=>{
							this.openDoor(arr[0].deviceCode)
						}).catch(_=>{})
						break;
					default:
						break;
				}
			},
			openDoor (datas) {
				this.$Axios._get({
					url: this.$Config.apiUrl.openDoor,
					method: "get",
					params: {
						deviceCode : datas
					}
				}).then(res => {
					if(res.result.code == 0){
						this.$message.success('操作成功')
					}else{
						this.$message.error(res.result.message)
					}
				})
			},
			devUseOrNotUse (datas,index) {
			    if(datas.canUse == 0){
			        this.handlerOperationDev('4#'+index)
			    }else if(datas.canUse == 1) {
			        this.handlerOperationDev('3#'+index)
			    }
			},
			
			// 解绑设备
			unbindDev (datas) {
			    this.$Axios._post({
			        url: this.$Config.apiUrl.batchUnBindDevice,
			        method: "post",
			        params: {
			            deviceCodes : datas
			        }
			    }).then(res => {
			        if(res.result.code == 0){
			            this.$message.success('操作成功')
			            this.pageParam.freshCtrl++; // 重新请求表格数据
			        }else{
			            this.$message.error(res.result.message)
			        }
			    })
			},
			// 新增设备
			handlerAddDev (command) {
			    if(command == 1) {
			        // 批量导入
			    }else if(command == 2) {
			        this.$router.push({
			            path: '/device/device-add'
			        })
			    }
			},
			// 参数下发
			devParamSet (datas,type='set') {
			    this.$refs.paramSet.devDatas = datas
			    this.$refs.paramSet.setType = type
			    this.$refs.paramSet.deviceId = type == 'single'?datas[0].deviceId:''
			    this.$refs.paramSet.dialogVisible = true
			},
			// 启用设备
			setCanUse (datas) {
			    this.$Axios._post({
			        url: this.$Config.apiUrl.batchSetDeviceCanUse,
			        method: "post",
			        params: {
			            deviceCodes : datas
			        }
			    }).then(res => {
			        if(res.result.code == 0){
			            this.$message.success('操作成功')
			            this.pageParam.freshCtrl++; // 重新请求表格数据
			        }else{
			            this.$message.error(res.result.message)
			        }
			    })
			},
			setNotUse (datas) {
			    this.$Axios._post({
			        url: this.$Config.apiUrl.batchSetDeviceStopUse,
			        method: "post",
			        params: {
			            deviceCodes : datas
			        }
			    }).then(res => {
			        if(res.result.code == 0){
			            this.$message.success('操作成功')
			            this.pageParam.freshCtrl++; // 重新请求表格数据
			        }else{
			            this.$message.error(res.result.message)
			        }
			    })
			},
			// 禁用设备
			getDeviceInfo (datas) {
			    this.$router.push({
			        path: '/device/device-info',
			        query:{
			            deviceCode: datas.deviceCode
			        }
			    })
			},
			// 远程重启
			batchRebootDev (datas) {
			    this.showPop()
			    this.$Axios._post({
			        url: this.$Config.apiUrl.batchReboot,
			        method: "post",
			        noShowLoading: true,
			        params: {
			            deviceCodes : datas
			        }
			    }).then(res => {
			        this.$refs['aPoppup'].close()
			        if(res.result.code == 0){
			            this.rebootResult = res.result.data
			            this.$refs['rebootResult'].dialogVisible = true
			            this.pageParam.freshCtrl++; // 重新请求表格数据
			        }else{
			            this.$message.error(res.result.message)
			        }
			    }).catch(()=>{
			        this.$refs['aPoppup'].close()
			    })
			},
			// 显示弹窗
			showPop () {
			    this.$refs['aPoppup'].open({
			        title: '设备重启中',
			        subTitle: '预计等待时间1分30秒',
			        showConfirm: true,
			        showCancel: false,
			        confirmText: '确认',
			        cancelText: '取消'
			    },(res)=>{
			        console.log(res)
			    })
			},
			paramsSetResult (datas) {
			    this.$refs.paramResult.tableData = datas
			    this.$refs.paramResult.dialogVisible = true
			},
			// 下发参数重试
			resetParams (datas) {
			    this.devParamSet(datas,'reset')
			},
			// 拼接设备小程序二维码
			setQrcode (datas) {
				let url = datas.qrcodeUrl.replace('#{code}',datas.deviceCode)
            	this.$refs['devQrCode'].qrVal = url
			}
		}
	}
</script>
<style lang="scss" scoped>
.device-off{
        width: 6px;
        height: 6px;
        background: #909499;
        border-radius: 50%;
        margin-right: 6px;
    }
    .device-online{
        width: 6px;
        height: 6px;
        background: #29CC29;
        border-radius: 50%;
        margin-right: 6px;
    }
    .slotUseStatus{
        width: 48px;
        height: 24px;
		min-height: 24px !important;
        background: #F4F6F9;
        margin-bottom: 8px;
        display: inline-block;
        span{
            display: block;
            width: 48px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            margin-top: 4px;
        }
        .grayBorder{
            border-right: 1px solid #E3E3E3;
        }
        .slotUsing{
            color: #29CC29;
        }
        .slotNotUse{
            color: #909499;
        }
    }
    .c29CC29{
        color: #29CC29;
    }
    .cFFA900{
        color: #FFA900
    }
    .cFF3B30{
        color: #FF3B30
    }
    .width280{
        width: 280px;
    }
    .progress{
        width: 205px;
        height: 7px;
        border-radius: 4px;
        margin: 13px 0 24px;
        background: #B9B9B9
    }
    .progress-item{
        width: 100%;
        height: 7px;
        border-radius: 4px;
        background: #007AFF;
        animation: progressPop 10s;
        -webkit-animation: progressPop 5s; /* Safari and Chrome */
    }
    /* 动画 */
    @keyframes progressPop
    {
        from {width: 0;}
        to {width:100%;}
    }

    @-webkit-keyframes progressPop /* Safari and Chrome */
    {
        from {width: 0;}
        to {width:100%;}
    }
</style>
