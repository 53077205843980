<template>
	<div>
		<div v-if="choosePriceId||priceId">
			<le-price-detail :priceId="choosePriceId||priceId" @choosePrice="handlerBindPrice">
			</le-price-detail>
			
		</div>
		<div v-else>
			<div class="no_plan a-flex-rcc" @click="handlerBindPrice">
				<img src="../../assets/icon/option-add-blue.png" class="a-wh-21 a-cursor-p" />
				<span class="a-c-blue a-fs-16 a-ml-12 a-cursor-p" >添加定价</span>
			</div>
			
		</div>
		<le-price-choose :visible.sync="priceChooseVisible" :priceId.sync="choosePriceId"></le-price-choose>
	</div>
</template>
<script>
	import util from '../../../src/utils/util'
	import LePriceChoose from '../components/choose/le-price-choose.vue'
	import LePriceDetail from '../components/page/le-price-detail.vue'
	export default {
		components: {
			'le-price-choose': LePriceChoose,
			'le-price-detail':LePriceDetail
		},
		props: {
			stationId: {
				required: true
			},
			priceId: {
				required: true
			}
		},
		data() {
			return {
				util:util,
				priceChooseVisible:false,
				choosePriceId:''
			}
		},

		watch: {
			choosePriceId:{
				handler(val) {
					if (val) {
						this.bindPrice()
					}
				},
			}
		},
		methods: {
			handlerBindPrice() {
				console.log('handlerBindPrice')
				this.priceChooseVisible = true;
			},
			/**
			 * 绑定站点定价
			 */
			bindPrice(){
				this.$Axios._get({
				    url: this.$Config.apiUrl.bindPlan,
				    params: {
				        stationId: this.stationId,
						planId :this.choosePriceId
				    }
				}).then(({data}) => {
					this.$message.success("绑定站点定价成功")
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.no_plan {
		width: 336px;
		height: 156px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #DADDE0;
	}
</style>
