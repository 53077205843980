<template>
	<div class="a-w-100 a-h-100 delivery">
		<div class="delivery-top">
			<span class="a-fs-16 a-fw-700">申请信息</span>
			<div class="delivery-top-box a-flex-rfsc a-flex-wrap">
				<div>
					<span>申请数量</span>
					<span>{{  deviceApply.applyDeviceNum  }}</span>
				</div>
				<div>
					<span>收货人</span>
					<span>{{  deviceApply.personName  }}</span>
				</div>
				<div>
					<span>联系电话</span>
					<span>{{  deviceApply.mobile  }}</span>
				</div>
				<div>
					<span>收货地址</span>
					<span>{{  deviceApply.addressProvince + deviceApply.addressCity + deviceApply.addressDistrict +
					deviceApply.detailAddress

						}}</span>
				</div>
				<div>
					<span>申请人</span>
					<span>{{  deviceApply.applyUserName  }}</span>
				</div>
				<div>
					<span>申请人电话</span>
					<span>{{  deviceApply.applyUserMobile  }}</span>
				</div>
			</div>
		</div>
		<el-form :model="deliveryform" :rules="rules" ref="deliveryForm" label-width="112px" class="deliveryform"
			label-position="left">

			<div class="a-mt-56">
				<div class="a-fs-16 a-fw-700">出库信息</div>

				<el-form-item label="使用教程及规范" class="a-mt-16">
					<el-input v-model="deliveryform.deliveryHelpDocNum" placeholder="请填写数量" type="number" maxlength="5">
					</el-input>
				</el-form-item>
				<el-form-item label="宣传海报">
					<el-input v-model="deliveryform.deliveryAdvertseNum" placeholder="请填写数量" type="number"
						maxlength="5"> </el-input>
				</el-form-item>
				<el-form-item label="设备发货数量" prop="deliveryDeviceNum">
					<el-input v-model="deliveryform.deliveryDeviceNum" placeholder="请填写数量" maxlength="50"> </el-input>
				</el-form-item>
				<el-form-item label="出货地址" prop="deliveryAddress">
					<el-input v-model="deliveryform.deliveryAddress" placeholder="请填写地址" maxlength="50"> </el-input>
				</el-form-item>


				<el-form-item label="物流公司" prop="shipCompany">
					<el-input v-model="deliveryform.shipCompany" placeholder="请填写公司名称" maxlength="50"> </el-input>
				</el-form-item>
				<el-form-item label="物流单号">
					<el-input v-model="deliveryform.shipNumber" placeholder="请填写" maxlength="50">
					</el-input>
				</el-form-item>

			</div>
			<div class="a-line-t-e0 ">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="onSubmit">立即提交</el-button>
				<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancle">
					&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
				</el-button>
			</div>
		</el-form>


	</div>
</template>

<script>
export default {
	props: {
		stationId: {
			type: Number,
		}
	},
	data() {
		return {
			deviceApply: {

			},
			station: {
				name: '',
				incomeName: ''
			},


			deliveryform: {
				deliveryAddress: '',
				deliveryHelpDocNum: 0,
				deliveryAdvertseNum: 0,
				deliveryDeviceNum: 0,
				shipCompany: '',
				shipNumber: '',
			},
			rules: {
				deliveryAddress: [{
					required: true,
					message: '请输入发货地址',
					trigger: 'blur'
				}],
				shipCompany: [{
					required: true,
					message: '请输入物流公司名称或者填写线下配送',
					trigger: 'blur'
				}],
				deliveryDeviceNum: [{
					required: true,
					message: '请输入申请数量',
					trigger: 'blur'
				}],
			}
		}
	},
	watch: {
		stationId: {
			handler(val) {
				if (val) {
					this.getStationDeviceShipInfo();
					this.getStationDetail();
				}
			},
			immediate: true
		}
	},
	methods: {
		/**
		 * 获取站点出库详情
		 */
		getStationDeviceShipInfo() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getStationDeviceShipInfo,
				params: {
					stationId: this.stationId
				}
			}).then(({ data }) => {
				this.deviceApply = data
			})
		},
		/**
		 * 获取站点详细信息
		 */
		getStationDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getStationDetail,
				params: {
					stationId: this.stationId
				}
			}).then(({
				data
			}) => {
				this.station.name = data.station.name
				this.station.incomeName = data.incomeDistri.name
			})
		},
		onSubmit() {
			let that = this
			this.$refs.deliveryForm.validate((valid) => {
				if (valid) {
					that.$Axios._post({
						url: that.$Config.apiUrl.deliveryStationDevice,
						params: {
							...that.deliveryform,
							deviceShipId: that.deviceApply.id
						}
					}).then(({
						data
					}) => {
						/**
						 * 发送刷新
						 */
						this.$eventBus.$emit('station_fresh_deviceDelivery')
						this.$message.success("设备出库成功")
						this.$emit('success')
					})
				}
			});

		},
		handlerCancle() {
			this.$emit('cancel')
		}
	}
}
</script>

<style lang="scss" scoped>
.delivery {
	&-top {
		&-box {
			>div {
				width: 30%;
				margin-top: 16px;
				display: flex;
				flex-flow: row;
				justify-content: flex-start;

				span {
					font-size: 14px;
					line-height: 22px;


					&:nth-of-type(1) {
						color: #909499;
						margin-right: 16px;
						min-width: 80px;

					}

					&:nth-of-type(2) {
						width: 200px;
					}
				}
			}
		}
	}

	/deep/ .el-form-item {
		width: 50%;
		display: inline-block;

		.el-input__inner {
			// width: 378px;
			width: 90%;
		}
	}

	.footerBox {
		left: -24px;
		right: 24px;
		position: absolute;
		bottom: -20px;
		background: #fff;
		padding-bottom: 24px;
		margin-bottom: 0;
	}
}

.deliveryform {
	position: relative;
	height: 100%;
}

.el-main {
	height: calc(100vh - 195px);
}
</style>
