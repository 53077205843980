<template>
    <div>
        <el-table
            :data="stationBanPayChannel"
            max-height="500px"
            style="width: 800px;">
            <el-table-column
                label="支付方式"
                min-width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.banPayChannel | initDic(payChannelDic) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="线上/实体卡"
                min-width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.online?'线上':'实体卡' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="订单类型"
                min-width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isEdit: false,
                payChannelDic: [],
                orderTypeDic: [],
                stationBanPayChannel: []
            };
        },
        props:{
            stationId: {
                default: ''
            },
        },
        watch: {
			stationId:{
				handler(val) {
					if (val) {
						this.getBanPayChannel()
					}
				},
			}
		},
        created () {
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
            this.$getDic('orderType').then(res=>{ this.orderTypeDic = res; })
        },
        methods:{
            getBanPayChannel () {
                this.$Axios._get({
					url: this.$Config.apiUrl.getBanPayChannel,
					params: {
						stationId: this.stationId
					}
				}).then(({
					data
				}) => {
					this.stationBanPayChannel = data
				})
            },
            submit () {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .a-mr-12{
        margin-right: 12px;
    }
</style>