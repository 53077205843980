<template>
	<div class="app-body">
		<le-pagview ref="inspectionPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.inspectionList">
            <el-table ref="inspectionList" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                <el-table-column prop="sn" label="任务ID" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.sn || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="巡检点" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.stationName || "" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="巡检点ID" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.stationId || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="站点类型" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.stationType | initDic(stationTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="巡检类型" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.patrolType | initDic(patrolTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="巡检模版" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.patrolTemplateName || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="地址" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.address || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="发布时间" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.sendTime || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="截止时间" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.endTime || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="完成时间" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.ompleteTime || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="任务时限" min-width="120">
                    <template slot-scope="{ row }">
                        <span v-if="!$getDay.enumerateDaysBetweenDates(row.sendTime,row.endTime).length">1天</span>
                        <span v-else>{{ $getDay.enumerateDaysBetweenDates(row.sendTime,row.endTime).length }}天</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="执行人" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.solveRbacUserName || "" }} - {{ row.solveRbacUserPhone }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="重要程度" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.importanceLevel | initDic(importanceLevelDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="状态" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.status | initDic(statusDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderId" label="关联工单" min-width="120">
                    <template slot-scope="{ row }">
                        <span v-for="(item, index) in row.workOrderVoList" :key="index">{{ row.errorType | initDic(errorTypeDic) }}/</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="巡检详情">
                            <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handleToInfo(scope.row)" />
                        </el-tooltip>
                        <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                            <el-dropdown placement="bottom-start" @command="handlerOperation">
                                <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item 
                                        :command='"gb"+"#"+scope.$index' 
                                        v-if="scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)">关闭</el-dropdown-item>
                                    <!-- <el-dropdown-item :command='"jcgq"+"#"+scope.$index'>解除挂起</el-dropdown-item> -->
                                    <el-dropdown-item :command='"bgzxr"+"#"+scope.$index' v-if="scope.row.status == 1 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)">指派</el-dropdown-item>
                                    <el-dropdown-item 
                                        :command='"zycd"+"#"+scope.$index' 
                                        v-if="scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)">重要程度</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
        <change-executor ref="changeExecutor" @handlerSuccess='handlerChangeExecutorSuccess' :tableDataItem='currentDatas' type='inspection'></change-executor>
        <change-import-level ref="importLevel" @handlerSuccess='handlerChangeImportantSuccess' :tableDataItem='currentDatas'></change-import-level>
	</div>
</template>

<script>
let _this
import util from "../../utils/util.js";
import changeExecutor from '../workOrder/child/change-executor.vue';
import ChangeImportLevel from '../workOrder/child/change-importLevel.vue';
import { mapState } from 'vuex';
export default {
  components: { changeExecutor, ChangeImportLevel },
	data() {
		return {
			util: util,
			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.inspectionPage,
				method: "post",
				params: {
					provinceCode: "",
                    cityCode: "",
					districtCode: "",
                    sn: '',
                    stationType: '',
                    status: '',
                    importantLevel: '',
                    stationId: '',
                    patrolType: '',
                    sendStartTime: '',
                    sendEndTime: '',
                    solveRbacUserId: '',
                    doneStartTime: '',
                    doneEndTime: ''
				},
                freshCtrl: 1,
			},
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 6);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 30);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 91);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 100
            },//站点数据
            optionsSolveRbacUser: {
                url: this.$Config.apiUrl.getExecutor,
                method: "post",
                params: {
                },
                showLoading: false,
                labelKey: "userName",
                otherLabelKey: "companyName",
                valueKey: "id",
                searchKey: "name", 
                pageSize: 200
            },
            stationTypeDic: [],
            statusDic: [],
            importanceLevelDic: [],
            patrolTypeDic: [],
            errorTypeDic: [],
            currentDatas: '',
		}
	},
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('stationType','select').then(res=>{ this.stationTypeDic = res; })
        this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
        this.$getDic('patrolStatus','select').then(res=>{ this.statusDic = res; })
        this.$getDic('patrolType','select').then(res=>{ this.patrolTypeDic = res; })
        this.getErrorTypeDic()
        _this = this
    },
    props: {
        stationId: {
            default: ''
        }
    },
    watch: {
        stationId: {
            handler(val) {
                if (val) {
                    this.pageParam.params.stationId = this.stationId
                }
            },
            immediate: true
        }
    },
	mounted() { },
	methods: {
        getErrorTypeDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorType,
                method: "post",
                params: {},
            })
            .then(({ data })=>{
                this.errorTypeDic = data.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
            .catch(err=>{

            })
        },
		setTableData(data) {
			this.tableData = data;
		},
        handlerRest() {
			this.pageParam.params = {
				provinceCode: "",
                cityCode: "",
                districtCode: "",
                sn: '',
                stationType: '',
                status: '',
                importantLevel: '',
                stationId: '',
                patrolType: '',
                sendStartTime: '',
                sendEndTime: '',
                solveRbacUserId: '',
                doneStartTime: '',
                doneEndTime: ''
			};
			this.handlerSearch()
		},
		handlerSearch() {
			this.$refs['inspectionPage'].pageNum = 1
			this.pageParam.freshCtrl++;
		},
        // 创建巡检
        editWorkOrder () {
            this.$router.push({
                path:'/inspection/inspection-edit',
            })
        },
        handleToInfo (datas) {
            // this.$router.push({
            //     path:'/inspection/inspection-info',
            //     query: {
            //         id: datas.id?datas.id:''
            //     }
            // })
            this.$router.push({
                path:'/inspection/inspection-info',
                query: {
                    id: datas.id?datas.id:'',
                    questionPaperId: datas.questionPaperId,
                    answerPaperId: (datas.questionAnswerId ? datas.questionAnswerId : '')
                }
            })
        },
        // 修改执行人
        handlerChangeExecutorSuccess (datas) {
            let reqData = {
                patrolId: this.currentDatas.id,
                rbacUserId: datas.solveRbacUser,
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionChangeSolveUser, 'post')
        },
        handlerChangeImportantSuccess (datas) {
            let reqData = {
                patrolId: this.currentDatas.id,
                ...datas,
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionImportance, 'post')
        },
        // 更多操作
        handlerOperation (commands) {
            let command = commands.split('#')[0]
            let index = commands.split('#')[1]
            switch (command) {
                case 'gb':
                    // 挂起
                    this.$confirm('是否确认关闭?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        reqData = {
                            patrolId: this.tableData[index].id,
                        }
                        this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionPause, 'get')
                    }).catch(_=>{})
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$confirm('是否确认解除挂起?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                       
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.currentDatas = this.tableData[index]
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.currentDatas = this.tableData[index]
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        handleSubmitOperate (reqData, apiUrl, method) {
            if(method == 'get') {
                this.$Axios._get({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then((res)=>{
                    this.currentDatas = ''
                    this.pageParam.freshCtrl++;
                })
                .catch(err=>{

                })
            }else {
                this.$Axios._post({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then((res)=>{
                    this.currentDatas = ''
                    this.pageParam.freshCtrl++;
                })
                .catch(err=>{

                })
            }
            
        },
	},
};
</script>

<style lang="scss" scoped>
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
        height: 100% !important;
    }
</style>
